.Home-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-top: 30px;
    background: #e0efee;
}

.Home {
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    background: linear-gradient(145deg, #fff, #c9e1df);
    box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
    border-radius: 10px;
    margin: 20px auto;
    padding: 15px;
    transition: all 0.3s ease-in-out;
}

.Home:hover {
    transform: scale(1.02);
}

.App-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1.5;
    transition: all 0.3s ease-in-out;
}

.App-Container:hover {
    border-radius: 15px;
    background: #f6f6f6;
    transform: translateY(-5px);
}

.App-Container h3 {
    margin-bottom: 0;
    font-size: 12px;
    text-align: left;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.App-Container img {
    width: 100px;
    border-radius: 15px;
    box-shadow: 0 1.95px 2.6px rgba(0, 0, 0, 0.15);
}

.App-Container p {
    margin: 0;
    font-size: 13px;
    text-align: left;
    width: 110px;
}

.ant-menu {
    background-color: #185c7e;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    justify-content: space-between;
    height: 70px;
}

.ant-menu-item {
    order: 0 !important;
}

.ant-menu::before, .ant-menu::after {
    content: none;
}

.Footer {
    display: flex;
    font-family: "Urbanist", sans-serif;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 35px;
    background-color: #185c7e;
    position: relative;
    padding: 6px 0;
    box-shadow: 0 54px 55px rgba(0, 0, 0, 0.25), 0 -12px 30px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.12), 0 12px 13px rgba(0, 0, 0, 0.17), 0 -3px 5px rgba(0, 0, 0, 0.09);
}

.Footer-Logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Footer-Logo p {
    margin-right: 10px;
    font-size: 1.2em;
    color: #ecf0f1;
}

.Search-Result-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
    flex-wrap: wrap;
}

.Search-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70vw;
    margin: auto;
}

.Search-Container span {
    color: grey;
    font-size: 20px;
    font-weight: bold;
}

.Footer-Links {
    display: flex;
    justify-content: space-between;
    width: 250px;
    padding: 0 10px;
}

.Footer-Links a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.Footer-Links a:hover, .Footer-Links a:focus {
    color: #63c0d6;
}

.Load-More {
    background-color: #63c0d6;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 4px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 1.95px 2.6px rgba(0, 0, 0, 0.15);
    font-family: "Urbanist", sans-serif;
    transition: background-color 0.3s ease-in-out;
}

.Load-More:hover {
    background-color: #0f172a;
}

.area {
    position: relative;
    background: linear-gradient(145deg, #fff, #c9e1df);
    width: 70%;
    height: 30vh;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
    padding: 15px;
    margin: auto;
    color: #0f172a;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.area span {
    z-index: 2000;
    font-size: 3em;
    font-weight: bold;
}

.area p {
    z-index: 2000;
    width: 70%;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgb(100, 192, 215);
    animation: animate 25s linear infinite;
    bottom: -150px;
    border-radius: 20px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(-120vh) rotate(0deg);
        opacity: 0;
        border-radius: 0;
    }
    100% {
        transform: translateY(0) rotate(720deg);
        opacity: 1;
        border-radius: 50%;
    }
}

@media (max-width: 1300px) {
    .area span {
        font-size: 2em;
    }
    .area p {
        display: none;
    }
}
